import styled, { css } from "styled-components";
import { Zoom, Slide } from "react-awesome-reveal";

interface StyleProps {
  color: string;
  templateColumns?: string;
}

interface IsMobileProps {
  isMobile: boolean;
}

export const ZoomAnimationContainer = styled(Zoom)`
  display: flex;
  justify-content: center;
`;

export const AnotherHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0px;
  color: inherit;
  z-index: 1;
`;

export const HeadingSubtext = styled(AnotherHeading)<IsMobileProps>(
  ({ isMobile }: IsMobileProps) => css`
    display: ${isMobile ? "block" : "flex"};
    font-size: 1.2rem;
    margin-top: 12px;
    font-weight: 500;
  `
);

export const SubHeading = styled.h2`
  font-size: 4em;
  display: flex;
  align-items: center;
  margin: 0px;
  color: inherit;
  z-index: 1;

  @media screen and (max-width: 450px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const CvSubHeading = styled(SubHeading)`
  color: #031f48;
  font-size: 2em;
`;

export const SectionContainer = styled.section<StyleProps>(
  ({ color }: StyleProps) => css`
    background-color: ${color};
  `
);

export const Section = styled.div<StyleProps>(
  ({ color, templateColumns }: StyleProps) => css`
    background-color: ${color};
    display: grid;
    grid-template-columns: ${templateColumns};
    align-items: center;
    padding: 36px;

    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr;
    }
  `
);

export const UniqueSection = styled.div<IsMobileProps>(
  ({ isMobile }: IsMobileProps) => css`
    background-color: #fff;
    display: flex;
    flex-direction: ${isMobile ? "column-reverse" : "row"};
    align-items: center;
    padding: 36px;

    @media screen and (max-width: 990px) {
      grid-template-columns: 1fr;
    }
  `
);

export const BottomSection = styled(Section)`
  @media screen and (max-width: 1140px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
`;

export const Separator = styled(StyledSeparator)`
  top: 5px;
`;

export const BottomSeparator = styled.img`
  width: 100%;
  position: relative;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 990px) {
  }
`;

export const Text = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  color: #150d6b;
  text-align: center;

  @media screen and (max-width: 990px) {
    max-width: 100%;
  }
`;

export const TextContent = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: #150d6b;
  line-height: 160%;
  margin-bottom: 1.5em;
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #150d6b;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1140px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  border-radius: 50%;
  margin-left: 12px;
`;

export const Name = styled.img`
  width: 50%;
`;

export const AnotherImage = styled(Image)`
  width: 28rem;
  border-radius: 817px 539px 675px;
  position: absolute;
  top: -30px;
  left: 90px;

  &:hover {
    transform: unset;
    cursor: unset;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: #150d6b;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid #150d6b;
  padding: 14px 40px 14px 28px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }

  @media screen and (max-width: 990px) {
    align-self: center;
  }
`;

export const CustomerStoryHeading = styled.h4`
  font-size: 1.875rem;
  font-weight: 700;
  max-width: 80%;
  color: #150d6b;
  margin: 12px 0;

  @media screen and (max-width: 1140px) {
    max-width: unset;
  }
`;

export const MainHeading = styled.h1`
  font-size: 1.5rem;
  margin: 0px;
  color: inherit;
  z-index: 1;
`;

export const Testimonials = styled.div`
  background-color: #f7f8fc;
  display: flex;
  justify-content: center;
`;

export const Document = styled.img`
  width: 48px;
  margin-left: 12px;
  cursor: pointer;

  &:hover {
    opacity: 50%;
  }
`;

export const HeadingOuterContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  @media screen and (max-width: 746px) {
    grid-template-columns: 1fr;
  }
`;

export const HeadingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CvSection = styled.div`
  background-color: rgb(247, 248, 252);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px;
  line-height: 1.3;
`;

export const HeadingImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 746px) {
    display: none;
  }
`;

export const NameAndDocContainer = styled.div`
  display: flex;

  @media screen and (max-width: 746px) {
    justify-content: space-evenly;
  }
`;
export const Cv = styled.div`
  width: 70%;
  background-color: #fff;
  box-shadow: #aaa 2px 2px 10px;
  padding: 36px;

  @media screen and (max-width: 746px) {
    width: unset;
    padding: 12px;
  }
`;

export const Links = styled.ul`
  all: unset;
  list-style: none;
  display: flex;
`;

export const Link = styled.li`
  margin-right: 12px;
`;
export const DocContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const BannerText = styled(Slide)`
  display: flex;
  flex-direction: column;
`;
