import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  codingBlackFemaless,
  glowingInTech,
  logoLight,
  renee2,
  slantedLineSeparatorDark,
  slantedLineSeparatorWhite,
  triangirls,
} from "../../assets";
import { trackEvent } from "../../helpers";
import { Background } from "../background";
import { Footer } from "../footer";
import { Separator } from "../footer/footer.styles";

interface Event {
  title: string;
  date: string;
  location: string;
  description: string;
  link?: string;
  image?: string;
}

interface SocialPlatform {
  platform: string;
  handle: string;
  link: string;
  description: string;
  followers: string;
  youtube?: boolean;
}

const Container = styled.div`
  min-height: 100vh;
  background-color: white;
  color: #1f2937; /* gray-900 */
`;

const Main = styled.main`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem;
`;

const Highlight = styled.span`
  color: #4f46e5; /* indigo-600 */
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #4f46e5; /* indigo-600 */
`;

const EventCard = styled.div`
  background-color: #f9fafb; /* gray-50 */
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }

  /* Ensure card scales correctly */
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
`;

const EventContent = styled.div`
  padding: 1.5rem;
  flex: 1;
`;

const EventDate = styled.div`
  color: #4f46e5; /* indigo-600 */
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const EventTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const EventLocation = styled.p`
  color: #4b5563; /* gray-600 */
  margin-bottom: 0.5rem;
`;

const EventDescription = styled.p`
  color: #2d3748; /* gray-800 */
  margin-bottom: 1rem;
`;

const EventLink = styled.a`
  color: #4f46e5; /* indigo-600 */
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #3730a3; /* indigo-800 */
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 2rem;

  /* For the Past Speaking Events section: 4 columns on large screens */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  /* For small screens, the grid will have fewer columns */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
`;

const SocialCard = styled.div`
  background-color: #f9fafb; /* gray-50 */
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
`;

const PlatformTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const PlatformHandle = styled.p`
  font-family: monospace;
  color: #4f46e5; /* indigo-600 */
  margin-bottom: 0.5rem;
`;

const PlatformFollowers = styled.p`
  color: #4b5563; /* gray-600 */
  margin-bottom: 1rem;

  span {
    color: #4f46e5; /* indigo-600 */
    font-weight: bold;
  }
`;

const PlatformDescription = styled.p`
  color: #2d3748; /* gray-800 */
  margin-bottom: 1rem;
`;

const FollowLink = styled.a`
  color: #4f46e5; /* indigo-600 */
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #3730a3; /* indigo-800 */
  }
`;

export const Speaking = () => {
  const pastEvents: Event[] = [
    {
      title: "TrianGirls",
      date: "March 31, 2022",
      location: "London, UK",
      description:
        "Talk on getting into Engineering via an accelerator program",
      link: undefined,
      image: triangirls,
    },

    {
      title: "Coding Black Females",
      date: "April 28, 2022",
      location: "London, UK",
      description: "Talk on career switching from Teaching to Tech",
      link: undefined,
      image: codingBlackFemaless,
    },
    {
      title: "Glowing In Tech",
      date: "16th July, 2022",
      location: "London, UK",
      description: "Talk on how I got into Engineering without a CS degree",
      link: "https://youtu.be/pDbmR_pq9x0?si=ZKtLjfKe299ckJoR",
      image: glowingInTech,
    },
    {
      title: "Women Of Silicon Roundabout 2024",
      date: "November 27, 2024",
      location: "London exCel, UK",
      description:
        "Building inclusive tech spaces & pathways for diverse talent",
      link: "https://www.buzzsprout.com/2115341/episodes/16200514-beyond-the-checkbox-building-inclusive-tech-spaces-and-pathways-for-diverse-talent-ft-renee-louise",
      image: renee2,
    },
  ];

  const upcomingEvents: Event[] = [];

  const socialContent: SocialPlatform[] = [
    {
      platform: "YouTube",
      handle: "@ReneeLouiseTech",
      link: "https://www.youtube.com/@reneelouisetech",
      description: "Weekly tech, business and motivational content",
      followers: "2.14K+",
      youtube: true,
    },
    {
      platform: "Blaq Byte",
      handle: "@BlaqByte",
      link: "https://instagram.com/blaqbyte",
      description: "Helping Black talent to navigate & thrive in tech",
      followers: "420+",
    },
    {
      platform: "Instagram",
      handle: "@Reneelouise.tech",
      link: "https://instagram.com/reneelouise.tech",
      description: "Behind-the-scenes, tech, business and motivation",
      followers: "1.5K+",
    },
    {
      platform: "TikTok",
      handle: "@Reneelouise.tech",
      link: "https://www.tiktok.com/@reneelouise.tech",
      description: "Quick tech and business tips",
      followers: "130+",
    },
  ];
  const navigate = useNavigate();

  const navigateAndTrack = (navigateTo: string, event: string) => {
    navigate(navigateTo);
    trackEvent(event);
  };

  return (
    <>
      <Background />
      <Separator src={slantedLineSeparatorWhite} alt=""/>
      <Container>
        <Main>
          <Title>
            Speaking <Highlight>Engagements</Highlight>
          </Title>

          <section>
            <SectionTitle>Past Speaking Events</SectionTitle>
            <Grid>
              {pastEvents.map((event, index) => (
                <EventCard key={index}>
                  {event.image && (
                    <EventImage src={event.image} alt={event.title} />
                  )}
                  <EventContent>
                    <EventDate>{event.date}</EventDate>
                    <EventTitle>{event.title}</EventTitle>
                    <EventLocation>{event.location}</EventLocation>
                    <EventDescription>{event.description}</EventDescription>
                    {event?.link && (
                      <EventLink href={event.link} target="_blank">
                        View Recording →
                      </EventLink>
                    )}
                  </EventContent>
                </EventCard>
              ))}
            </Grid>
          </section>

          <section>
            <SectionTitle>Upcoming Speaking Events</SectionTitle>
            {upcomingEvents.length > 0 ? (
              <Grid>
                {upcomingEvents.map((event, index) => (
                  <EventCard key={index}>
                    <EventContent>
                      <EventDate>{event.date}</EventDate>
                      <EventTitle>{event.title}</EventTitle>
                      <EventLocation>{event.location}</EventLocation>
                      <EventDescription>{event.description}</EventDescription>
                      <EventLink href={event.link}>Learn More →</EventLink>
                    </EventContent>
                  </EventCard>
                ))}
              </Grid>
            ) : (
              <p
                style={{ textAlign: "center", color: "grey", padding: "24px" }}
              >
                Nothing to see here <i>yet</i>, Stay tuned for upcoming events –
                feel free to{" "}
                <span
                  style={{ cursor: "pointer", color: "#4f46e5" }}
                  onClick={() =>
                    navigateAndTrack("/contact", "Contact mini click")
                  }
                  aria-label="Click this text to go to the contact us page"
                >
                  get in touch
                </span>{" "}
                to schedule a session.
              </p>
            )}
          </section>

          <section>
            <SectionTitle>Content Creation</SectionTitle>
            <Grid>
              {socialContent.map((platform, index) => (
                <SocialCard key={index}>
                  <PlatformTitle>{platform.platform}</PlatformTitle>
                  <PlatformHandle>{platform.handle}</PlatformHandle>
                  <PlatformFollowers>
                    Followers: <span>{platform.followers}</span>
                  </PlatformFollowers>
                  <PlatformDescription>
                    {platform.description}
                  </PlatformDescription>
                  <FollowLink href={platform.link} target="_blank">
                    {platform?.youtube ? "Subscribe →" : "Follow →"}
                  </FollowLink>
                </SocialCard>
              ))}
            </Grid>
          </section>
        </Main>
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={logoLight}
        />
      </Container>
    </>
  );
};
