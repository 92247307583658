import styled from 'styled-components'

export const PageWrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom right, #f9fafb, #f3f4f6);
`;

export const HeroSection = styled.div`
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const HeroContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 6rem 1rem 2rem;
  text-align: center;
`;

export const HeroTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

export const HeroDescription = styled.p`
  font-size: 1.125rem;
  color: #4b5563;
  margin: 0 auto;
  max-width: 42rem;
`;

export const ServicesGrid = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 4rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ServiceCard = styled.div`
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const ServiceCardContent = styled.div`
  padding: 2rem;
`;

export const ServiceHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const IconWrapper = styled.div`
  padding: 0.5rem;
  background-color: #e0e7ff;
  border-radius: 0.5rem;
`;

export const ServiceTitle = styled.h3`
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
`;

export const ServiceList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const ServiceItem = styled.li`
  display: flex;
  align-items: center;
  color: #4b5563;
  margin-bottom: 0.75rem;
`;

export const BulletPoint = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background-color: #6366f1;
  border-radius: 9999px;
  margin-right: 0.75rem;
`;

export const TitleIcon = styled.img`
  width: 50px;
`;

export const Text = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  // max-width: 80%;
  color: #150d6b;
  text-align: center;

  @media screen and (max-width: 990px) {
    max-width: 100%;
  }
`;