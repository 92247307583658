import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { Slide } from "react-awesome-reveal";

interface StyleProps {
  isScrolled?: boolean;
  isValidRoute?: boolean;
  isResumePage?: boolean;
}

interface IsMobileProps {
  isMobile?: boolean;
}

export const Nav = styled.nav<StyleProps>(
  ({ isValidRoute, isResumePage }: StyleProps) => css`
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    border-bottom: ${isValidRoute || isResumePage
      ? "none"
      : "0.5px solid #fff"};
    align-items: center;
    position: sticky;
    top: 0;
    background-color: ${!isValidRoute ? "white" : "none"};
    transition: all 0.2s ease 0s;
    z-index: 2;
  `
);

export const MobileNavbar = styled(Nav)`
  flex-direction: column;
`;

export const LogoContainer = styled.div``;

export const NavLinks = styled.div<StyleProps>(
  ({ isValidRoute }: StyleProps) => css`
    color: ${!isValidRoute ? "#150d6b" : "white"};
    display: flex;
    align-items: center;
    position: relative;
  `
);

export const MenuItem = styled(NavLink)<StyleProps>(
  ({ isScrolled, isValidRoute }: StyleProps) => css`
    margin: 12px;
    text-decoration: none;
    color: ${isScrolled || !isValidRoute ? "inherit" : "#fff"};

    &:hover {
      opacity: 0.5;
      transition: 0.3s ease-in-out;
    }
  `
);

export const LogoNavLink = styled(MenuItem)`
  &:hover {
    opacity: unset;
  }
`;

export const MobileMenuItem = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  font-size: 24px;
  color: #150d6b;
  width: 100vw;
  padding: 24px 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

export const MobileDropdownMenuItem = styled(MobileMenuItem)`
  background-color: #fff;
`;

export const ServicesMenuItem = styled(MobileMenuItem)`
  border-bottom: unset;
  padding: 24px 0px 24px 36px;
`;

export const Logo = styled.img`
  width: 200px;
`;

export const MobileNavLinks = styled(Slide)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  align-items: flex-start
  width:100%;
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px;
`;

export const SocialLink = styled.img`
  width: 3rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #150d6b;
  margin: 8px;
`;

export const MobileNavIconButton = styled.button`
  background-color: unset;
  border: none;
`;

export const SocialMediaButton = styled.button`
  background-color: unset;
  border: none;
`;

export const DownArrow = styled.img`
  rotate: 180deg;
  width: 16px;
  cursor: pointer;
`;

export const UpArrow = styled(DownArrow)`
  rotate: unset;
`;

export const DesktopDownArrow = styled(DownArrow)`
  width: 12px;
`;

export const DesktopUpArrow = styled(DesktopDownArrow)`
  rotate: unset;
`;

export const MenuItemDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuItemAndIcon = styled.div<StyleProps>(
  ({ isScrolled }: StyleProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: ${isScrolled ? "0px" : "1px solid rgba(0, 0, 0, 0.05)"};
    align-items: center;
  `
);

export const Button = styled.button<IsMobileProps>(
  ({ isMobile }: IsMobileProps) => css`
    border: none;
    background: none;
    width: fit-content;
    margin: ${isMobile ? "24px 36px" : "unset"};
    padding: 36px;
    cursor: pointer;
  `
);

export const DesktopButton = styled(Button)`
  padding: 0px;
  margin-right: 24px;
`;

export const ServicePagesNavLinks = styled(Slide)`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const DropDownLinks = styled.div<StyleProps>(
  ({ isScrolled, isValidRoute }: StyleProps) => css`
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    background: ${isScrolled || !isValidRoute
      ? "#fff"
      : "linear-gradient(rgb(21, 13, 107) 1.1%, rgb(188, 16, 80) 130.5%)"};
    border-radius: 0px 0px 4px 4px;
  `
);
