import React, { useEffect } from "react";

interface TikTokEmbedProps {
    videoId: string;
    username: string; 
    description: string; 
    tags: string[];
}

export const TikTokEmbed = ({ videoId, username, description, tags }: TikTokEmbedProps) => {
  useEffect(() => {
    // Dynamically load the TikTok embed script
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Construct the TikTok URL and embed information
  const videoUrl = `https://www.tiktok.com/@${username}/video/${videoId}`;

  return (
    <div>
      <blockquote
        className="tiktok-embed"
        cite={videoUrl}
        data-video-id={videoId}
        style={{ maxWidth: "605px", minWidth: "325px" }}
      >
        <section>
          <a
            target="_blank"
            title={`@${username}`}
            href={`https://www.tiktok.com/@${username}?refer=embed`}
            rel="noreferrer"
          >
            @{username}
          </a>
          {description && <p>{description}</p>}
          {tags &&
            tags.map((tag, index) => (
              <a
                key={index}
                title={tag}
                target="_blank"
                href={`https://www.tiktok.com/tag/${tag}?refer=embed`}
                rel="noreferrer"
              >
                #{tag}
              </a>
            ))}
        </section>
      </blockquote>
    </div>
  );
};

