import React from "react";
import { Slide } from "react-awesome-reveal";
import {
  careerGoals,
  consultation,
  growth,
  logoLight,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  slantedLineSeparatorWhite,
  training1,
} from "../../assets";
import { testimonials } from "../../helpers";
import { ContactMini } from "../contact-mini";
import { Footer } from "../footer";
import {
  BottomSeparator,
  Section,
  Separator,
  TextContainer,
} from "../service-page/service-page.styles";
import { Slider as TestimonialSlider } from "../../components/slider/slider";
import { Background } from "../background";
import {
  TitleIcon,
  BulletPoint,
  HeroContent,
  HeroDescription,
  HeroSection,
  HeroTitle,
  IconWrapper,
  PageWrapper,
  ServiceCard,
  ServiceCardContent,
  ServiceHeader,
  ServiceItem,
  ServiceList,
  ServiceTitle,
  ServicesGrid,
  Text,
} from "./sevices-page.styles";

const categories = [
  {
    title: "Career Development",
    icon: <TitleIcon src={careerGoals} />,
    services: [
      "CV & Resume Optimisation",
      "Career Coaching Sessions",
      "Portfolio Development / Review",
      "Mock Technical Interviews",
      "LinkedIn Profile Enhancement",
      "Personal Brand Content Strategies",
    ],
  },
  {
    title: "Technical Training",
    icon: <TitleIcon src={training1} />,
    services: [
      "Cohort-Based Coding Training",
      "Observability Training",
      "Accessibility Training",
      "Code Review Training",
      "Intensive React Framework Training",
      "Intensive Typescript Training",
    ],
  },
  {
    title: "Professional Growth",
    icon: <TitleIcon src={growth} />,
    services: [
      "Personal Branding Strategy",
      "Public Speaking Coaching",
      "Technical Writing Skills",
      "Leadership Development",
      "Networking Strategies",
      "Career Transition Guidance",
    ],
  },
  {
    title: "Consulting Services",
    icon: <TitleIcon src={consultation} />,
    services: [
      "Accessibility audit",
      "Architecture Review",
      "Tech Stack Optimisation",
      "Performance Audits",
      "Code Review Services",
      "Software Development",
      "Scalability Planning",
      "Observability Implementation",
    ],
  },
];

export const ServicesPage = () => {
  return (
    <>
      <Background />
      <Separator src={slantedLineSeparatorWhite} alt="" />
      <PageWrapper>
        <HeroSection>
          <HeroContent>
            <HeroTitle>Professional Services</HeroTitle>
            <HeroDescription>
              Elevate your tech career or business with personalised guidance,
              technical expertise, and professional development services.
            </HeroDescription>
          </HeroContent>
        </HeroSection>

        {/* Services Grid */}
        <ServicesGrid>
          {categories.map((category, index) => (
            <ServiceCard key={index}>
              <ServiceCardContent>
                <ServiceHeader>
                  <IconWrapper>{category.icon}</IconWrapper>
                  <ServiceTitle>{category.title}</ServiceTitle>
                </ServiceHeader>
                <ServiceList>
                  {category.services.map((service, serviceIndex) => (
                    <ServiceItem key={serviceIndex}>
                      <BulletPoint />
                      {service}
                    </ServiceItem>
                  ))}
                </ServiceList>
              </ServiceCardContent>
            </ServiceCard>
          ))}
        </ServicesGrid>
        <Separator src={slantedLineSeparator} alt="" />
        <Section color="#F7F8FC" templateColumns="1fr">
          <Slide direction="right" triggerOnce>
            <TextContainer>
              <Text>Testimonials</Text>
              <TestimonialSlider content={testimonials} />
            </TextContainer>
          </Slide>
        </Section>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <ContactMini
          services
          content="Ready to accelerate your tech journey?"
          color="none"
        />
        <Footer
          separator={slantedLineSeparatorDark}
          color="none"
          logo={logoLight}
        />
      </PageWrapper>
    </>
  );
};
