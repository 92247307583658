import styled from "styled-components"


export const Container = styled.div`
  min-height: 100vh;
  background-color: white; /* gray-50 */
`;

export const Main = styled.main`
  max-width: 7xl;
  margin: 0 auto;
  padding: 3rem 1rem;
  @media (min-width: 640px) {
    padding: 3rem 2rem;
  }
  @media (min-width: 1024px) {
    padding: 3rem 3rem;
  }
`;

export const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  color: #1f2937; /* gray-900 */
  text-align: center;
  margin-bottom: 3rem;

  span {
    color: #f87171; /* red-600 */
  }
`;

export const VideoWrapper = styled.div`
  margin-bottom: 4rem;
`;

export const FeaturedVideoCard = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const AspectRatioWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const VideoDetails = styled.div`
  padding: 1.5rem;
`;

export const VideoTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937; /* gray-900 */
  margin-bottom: 0.5rem;
`;

export const VideoDescription = styled.p`
  color: #4b5563; /* gray-600 */
  margin-bottom: 1rem;
`;

export const VideoMeta = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* gray-500 */
  gap: 0.75rem;
`;

export const VideoMetaItem = styled.span`
  &:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

export const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const VideoCard = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
`;

export const ThumbnailImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 16 / 9;
`;

export const DurationTag = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
`;

export const VideoContent = styled.div`
  padding: 1rem;
`;

export const VideoCardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: bold;
  color: #1f2937; /* gray-900 */
  margin-bottom: 0.5rem;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VideoCardDescription = styled.p`
  font-size: 0.875rem; /* text-sm */
  color: #4b5563; /* gray-600 */
  margin-bottom: 1rem;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VideoCardMeta = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem; /* text-sm */
  color: #6b7280; /* gray-500 */
  gap: 0.75rem;
`;

export const VideoCardMetaItem = styled.span`
  &:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

