import styled, { css } from "styled-components";

interface StyleProps {
  color?: string;
  templateColumns?: string;
}


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 56rem;
  color: #fff;
  height: 70vh;
  justify-content: center;
  padding: 36px;
`;

export const MainHeading = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0px;
  color: inherit;
  z-index: 1;
`;
export const SubHeading = styled.h2`
  font-size: 4em;
  margin: 0px;
  color: inherit;
  z-index: 1;

  @media screen and (max-width: 450px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const SectionContainer = styled.section<StyleProps>(
  ({ color }: StyleProps) => css`
    background-color: ${color};
  `
);

export const Section = styled.div<StyleProps>(
  ({ color, templateColumns }: StyleProps) => css`
    background-color: ${color};
    display: grid;
    grid-template-columns: ${templateColumns};
    align-items: center;
    padding: 36px;

    @media screen and (max-width: 990px) {
      display: flex;
      flex-direction: column;
    }
  `
);

export const BottomSection = styled(Section)`
  padding: 0px;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    padding: 36px;
  }
`;

export const AnotherSection = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
`;

export const StyledSeparator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
`;

export const Separator = styled(StyledSeparator)`
  top: 4px;
`;

export const BottomSeparator = styled(StyledSeparator)`
  top: 0px;
  rotate: 0deg;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  text-align: center;
`;

export const Text = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  color: #150d6b;
  text-align: center;

  @media screen and (max-width: 990px) {
    max-width: 100%;
  }
`;

export const ValueText = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: unset;
`;

export const TextContent = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: #150d6b;
  line-height: 160%;
  margin-bottom: 1.5em;
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #150d6b;
`;

export const CustomerStoriesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

export const Story = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const Circle = styled.div`
  border-radius: 613px 817px 539px 675px;
  height: 500px;
  width: 500px;
  border: 2px solid #150d6b;

  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Image = styled.img`
  cursor: pointer;

  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const StoryImage = styled.img`
  width: 28rem;
  height: 24rem;
  object-fit: cover;
  border-radius: 4px;



  @media screen and (max-width: 990px) {
    width: 100%;
  }
`;

export const AnotherImage = styled(Image)`
  border-radius: 817px 539px 675px;
  position: absolute;
  top: -60px;
  left: 90px;
`;

export const Type = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: #150d6b;
`;

export const Description = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
`;

export const ValueDescription = styled(Description)`
  @media screen and (max-width: 700px) {
    text-align: center;
    font-size: 1.2rem;
    pointer-events: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: center;
`;

export const Button = styled.button<StyleProps>(
  ({ color }: StyleProps) => css`
    cursor: pointer;
    width: fit-content;
    font-size: 1.125rem;
    color:${color ? "#fff" : "grey"};
    background-color: ${color || "#fff"};
    transition: all 0.2s ease-in-out 0s;
    border-radius: 30%;
    border: ${color ? "none" : "2px solid grey"};
    padding: 14px 24px;
    margin: 4px;
    border-radius: 100px;
    font-weight: 500;

    &:hover {
      background-color: #fff;
      color: #031f48;
      outline: ${color? '2px solid #031f48': 'none'};
      transition: smooth;
    }
  `
);

export const ValuesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-self: center;
  margin-top: 24px;
  gap: 4px;
  margin-left: 50px;

  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
    margin: 24px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 24px;
    width: 100%;
  }
`;

export const ValueContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 12px 0px;
    align-items: center;
    text-align: center;
  }
`;

export const ValueTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

export const ValueIcon = styled.img`
  width: 50px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #150d6b;
  padding: 8px;
  margin-bottom: 12px;
`;

export const Value = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  width: 90%;
  color: #150d6b;
  margin: 0;
`;


export const IFrame = styled.iframe`
`

export const CertificateImage = styled.img`
  max-width: 100%; /* Set the maximum width to 100% of its container */
  max-height: 100%; /* Set the maximum height to 100% of its container */
  height: auto; /* Allow the height to adjust proportionally to the width */
  width: auto;
`;