import {
  wavesSeparator,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  logoLight,
  renee1,
  renee2,
  renee3,
} from "../../assets";
import { Fade, Slide } from "react-awesome-reveal";
import { testimonials, phrases } from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  HeadingSubtext,
  Section,
  SectionContainer,
  Separator,
  TextContainer,
  Text,
  TextContent,
  BottomSeparator,
  UniqueSection,
} from "./about.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import { TypingEffect } from "../../components/typing-effect/typing-effect";
import { Slider } from "../../components/photo-slider/slider";
import { Slider as TestimonialSlider } from "../../components/slider/slider";

export const About = () => {
  const slideContent = [
    {
      id: 1,
      img: renee1,
      caption: "",
    },
    {
      id: 2,
      img: renee2,
      caption: "",
    },
    {
      id: 3,
      img: renee3,
      caption: "",
    },
  ];

  // check for mobile dimensions
  const { width } = useViewportDimensions();
  const isMobile = width <= 990;
  const isTablet = width <= 1250;

  return (
    <>
      <Background />
      <Container>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>About</MainHeading>
        </Fade>
        <Slide triggerOnce>
          <SubHeading>Renée-Louise</SubHeading>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <HeadingSubtext isMobile={isMobile}>
              A passionate and results-driven
              <span style={{ marginLeft: "6px" }}>
                <TypingEffect phrases={phrases} />
              </span>
            </HeadingSubtext>
          </Fade>
        </Slide>
      </Container>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <UniqueSection isMobile={isTablet}>
          <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
            <TextContent>
              Hi, I’m Renée! I’m a Software Engineer with over five years of
              experience in full-stack development, blending a passion for
              problem-solving with a mission to create inclusive and impactful
              tech solutions. My journey into tech hasn’t been conventional, but
              it’s one that’s been deeply shaped by a commitment to education,
              mentorship, and fostering diversity.
              <br />
              <br />
              After earning my Economics degree from the University of
              Leicester, I spent a year as a Mathematics teacher—an experience
              that deepened my love for education and mentorship. This passion
              eventually led me to pivot into software engineering, where I
              honed my technical skills through rigorous bootcamps sponsored by
              industry leaders like GCHQ, Goldman Sachs, and Beamery. These
              programs helped me transition into the world of tech, where I now
              focus on building scalable, user-centric applications.
              <br />
              <br />
              In addition to my technical career, I’m passionate about
              empowering others and advocating for inclusivity in the tech
              industry. I am the founder of{" "}
              <a href="https://devloop.sh" target="_blank" rel="noreferrer">
                Devloop.sh
              </a>
              , an ed-tech platform currently on the road to its MVP and set to
              launch in 2025. Devloop.sh is designed to help aspiring developers
              accelerate their careers with curated roadmaps, hands-on projects,
              and progress tracking—empowering users to build standout
              portfolios and unlock job opportunities in tech.
              <br />
              <br />I am also the Founder of{" "}
              <a href="https://blaqbyte.com" target="_blank" rel="noreferrer">
                Blaq Byte
              </a>
              , a community dedicated to helping underrepresented groups enter
              and thrive in tech. Through Blaq Byte, I host the Blaq Byte
              Podcast, which releases episodes weekly. The podcast features
              interviews with inspiring talents within the tech space, offering
              listeners a career navigation platform to help them break into the
              industry.
              <br />
              <br />
              Diversity is a core value in both my personal and professional
              life. I’ve had the privilege of volunteering with UN Women UK
              during the CSW67 and CSW68 sessions, where I worked to advance
              gender equality and women’s rights globally. These experiences
              have further solidified my commitment to creating equitable
              pathways for underrepresented voices in tech.
              <br />
              <br />
              I also actively mentor future developers. As a Full Stack
              Instructor with Code First Girls, I help women and non-binary
              individuals from underrepresented backgrounds enter and thrive in
              the tech space. Additionally, I’ve partnered with the EY
              Foundation to run workshops aimed at empowering young people from
              diverse backgrounds to pursue careers in STEM.
              <br />
              <br />
              Beyond building products and mentoring, I’m passionate about
              sharing knowledge and creating content that empowers others to
              thrive in tech, business, and life. I create educational content
              on YouTube, TikTok, and Instagram, breaking down complex concepts
              and making tech more accessible.
              <br />
              <br />
              Whether I’m writing code, running workshops, mentoring, creating
              content, or speaking at industry events, I am dedicated to using
              technology as a force for positive change, with a focus on
              creating opportunities and breaking down barriers for all.
            </TextContent>
          </Fade>
          <Slide direction="right" triggerOnce>
            <Slider content={slideContent} />
          </Slide>
        </UniqueSection>
        <Separator src={slantedLineSeparator} alt="" />
        <Section color="#F7F8FC" templateColumns="1fr">
          <Slide direction="right" triggerOnce>
            <TextContainer>
              <Text>Testimonials</Text>
              <TestimonialSlider content={testimonials} />
            </TextContainer>
          </Slide>
        </Section>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <ContactMini color="#fff" />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={logoLight}
        />
      </SectionContainer>
    </>
  );
};
