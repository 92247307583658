import React, { useState } from "react";
import {
  logoLight,
  slantedLineSeparatorDark,
  slantedLineSeparatorWhite,
} from "../../assets";
import { videos } from "../../helpers";
import { Separator } from "../../pages/resume/resume.styles";
import { Background } from "../background";
import { Footer } from "../footer";
import { YoutubeModal } from "../youtube-modal";
import {
  Container,
  Main,
  AspectRatioWrapper,
  DurationTag,
  FeaturedVideoCard,
  ThumbnailWrapper,
  Title,
  VideoCard,
  VideoCardDescription,
  VideoCardMeta,
  VideoCardMetaItem,
  VideoCardTitle,
  VideoContent,
  VideoDescription,
  VideoDetails,
  VideoGrid,
  VideoMeta,
  VideoMetaItem,
  VideoTitle,
  VideoWrapper,
  Iframe,
  ThumbnailImage,
} from "./youtube-videos.styles";

export const YouTube = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);

  const latestVideo = videos[0];
  const otherVideos = videos.slice(1);
  const handleOpenModal = (videoId: string) => {
    setCurrentVideoId(videoId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentVideoId(null);
  };

  return (
    <>
      <Background />
      <Separator src={slantedLineSeparatorWhite} alt="" />
      <Container>
        <Main>
          <Title>Latest YouTube Video</Title>

          {/* Featured Latest Video */}
          <VideoWrapper>
            <FeaturedVideoCard>
              <AspectRatioWrapper>
                <Iframe
                  src={`https://www.youtube.com/embed/${latestVideo.id}`}
                  title={latestVideo.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </AspectRatioWrapper>
              <VideoDetails>
                <VideoTitle>{latestVideo.title}</VideoTitle>
                <VideoDescription>{latestVideo.description}</VideoDescription>
                <VideoMeta>
                  <VideoMetaItem>{latestVideo.publishedAt}</VideoMetaItem>
                  <VideoMetaItem>{latestVideo.duration}</VideoMetaItem>
                </VideoMeta>
              </VideoDetails>
            </FeaturedVideoCard>
          </VideoWrapper>

          {/* Other Videos Grid */}
          <h2>More Videos</h2>
          <VideoGrid>
            {otherVideos.map((video) => (
              <VideoCard
                key={video.id}
                onClick={() => handleOpenModal(video.id)}
              >
                <ThumbnailWrapper>
                  <ThumbnailImage src={video.thumbnailUrl} alt={video.title} />
                  <DurationTag>{video.duration}</DurationTag>
                </ThumbnailWrapper>
                <VideoContent>
                  <VideoCardTitle>{video.title}</VideoCardTitle>
                  <VideoCardDescription>
                    {video.description}
                  </VideoCardDescription>
                  <VideoCardMeta>
                    <VideoCardMetaItem>{video.publishedAt}</VideoCardMetaItem>
                    <VideoMetaItem>{video.duration}</VideoMetaItem>
                  </VideoCardMeta>
                </VideoContent>
              </VideoCard>
            ))}
          </VideoGrid>
          {/* Modal */}
          {isModalOpen && currentVideoId && (
            <YoutubeModal videoId={currentVideoId} onClose={handleCloseModal} />
          )}
        </Main>
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={logoLight}
        />
      </Container>
    </>
  );
};
