import React, { useState, useEffect } from "react";
import { Button } from "./scroll-to-top.styles";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // Show button when the user scrolls 200px down
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!isVisible) return null; // Don't render button if it's not visible

  return (
    <Button onClick={handleScrollToTop} aria-label="Scroll to top">
      ↑
    </Button>
  );
};
