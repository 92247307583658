import { socialLinks, trackEvent } from "../../helpers";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import { SocialMediaButton } from "./footer.styles";
import {
  Footer,
  Logo,
  FooterContent,
  FooterText,
  SocialLinks,
  SocialLink,
  Link,
  Section,
  Separator,
} from "./footer.styles";

type FooterProps = {
  separator: string;
  color: string;
  logo: string;
};

export const FooterComponent = ({ logo, separator, color }: FooterProps) => {
  const { width } = useViewportDimensions();
  const displayFooterCopy = width >= 728 || width <= 567


  const navigateAndTrack = (navigateTo: string, event: string) => {
    window.open(navigateTo, "_blank");
    trackEvent(event)
  }

  return (
    <>
      <Section color={color}>
        <Separator src={separator} alt="" />
      </Section>
      <Footer>
        <Link to="/home">
          <Logo src={logo} alt="logo" />
        </Link>
        {displayFooterCopy && (
          <FooterContent>
            <FooterText>
              2025 Reneelouise.tech © All rights reserved.
            </FooterText>
          </FooterContent>
        )}
        <SocialLinks>
          {socialLinks.map((link, key) => {
            return (
              <>
                <SocialMediaButton
                  onClick={() => navigateAndTrack(`${link.url}`, `${link.alt} clicked`)}
                  aria-label={link.alt}
                >
                  <SocialLink
                    src={link.src.light}
                    alt={link.alt}
                    key={key}
                    aria-hidden="true"
                  />
                </SocialMediaButton>
              </>
            );
          })}
        </SocialLinks>
      </Footer>
    </>
  );
};
