import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

interface StyleProps {
  color: string;
}

export const Footer = styled.footer`
  background-color: #150d6b;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 567px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #fff;
  flex-direction: row;
  margin: 12px 0;
  @media screen and (max-width: 567px) {
    justify-content: center;
  }
`;

export const SocialLink = styled.img`
  width: 3rem;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid #fff;
  }
`;

export const FooterText = styled.div`
  color: grey;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 567px) {
    justify-content: center;
  }
`;

export const Logo = styled.img`
  width: 200px;
`;

export const BottomNav = styled.div`
  dispaly: flex;
  @media screen and (max-width: 567px) {
    justify-content: center;
  }
`;

export const Link = styled(NavLink)`
  all: unset;
  cursor: pointer;
  margin-right: 18px;
  text-decoration: none;
  color: #fff;

  &:hover {
    color: grey;
    transition: all 0.2s ease-in-out 0s;
  }

  @media screen and (max-width: 567px) {
    display: none;
  }
`;

export const Separator = styled.img`
  width: 100%;
  position: relative;
  rotate: 180deg;
  top: 4px;
  transform: scaleX(-1);
`;

export const Section = styled.div<StyleProps>(
  ({ color }: StyleProps) => css`
    background-color: ${color};
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 0px;
  `
);

export const SocialMediaButton = styled.button`
  background-color: unset;
  border: none;
`;
