import { useNavigate } from "react-router";
import { trackEvent } from "../../helpers";
import {
  ContactContainer,
  Heading,
  MainHeading,
  Button,
} from "./contact-mini.styles";

interface CustomProps {
  color?: string;
  heading?: string;
  content?: string;
  services?: boolean
}
export const ContactMini = ({ color, heading, content, services }: CustomProps) => {
  const navigate = useNavigate();

  const navigateAndTrack = (navigateTo: string, event: string) => {
    navigate(navigateTo);
    trackEvent(event);
  };
  

  const contactButtonText = services ? "Schedule Consultation" : "Contact me"


  return (
    <ContactContainer color={color}>
      {!services && <Heading>{heading ? heading : "Questions?"}</Heading>}
      <MainHeading>{content ? content : "Get in touch"}</MainHeading>
      {services && (
        <p style={{textAlign: 'center', padding: '0 12px'}}>
          Book a free consultation to discuss how we can help you achieve your
          professional goals.
        </p>
      )}
      <Button
        onClick={() => navigateAndTrack("/contact", "Contact mini click")}
        aria-label="Click this button to go to the contact us page"
      >
        {contactButtonText}
      </Button>
    </ContactContainer>
  );
};
