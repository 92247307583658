import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  wavesSeparator,
  lineSeparator,
  slantedLineSeparator,
  slantedLineSeparatorDark,
  singleLineWave,
  logoLight,
} from "../../assets";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import {
  extraStories,
  mobilePhrases,
  phrases,
  testimonials,
  tiktoks,
  trackEvent,
  videos,
} from "../../helpers";
import {
  Container,
  MainHeading,
  SubHeading,
  Section,
  SectionContainer,
  Separator,
  TextContainer,
  StoryImage,
  Text,
  AnotherSection,
  CustomerStoriesContainer,
  Story,
  Type,
  Description,
  ButtonGroup,
  Button,
  BottomSeparator,
  ValueText,
  IFrame,
} from "./home.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { TypingEffect } from "../../components/typing-effect/typing-effect";
import { Slider } from "../../components/slider/slider";
import "rc-slider/assets/index.css";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import {
  DurationTag,
  ThumbnailWrapper,
  VideoCard,
  VideoCardDescription,
  VideoCardMeta,
  VideoCardMetaItem,
  VideoCardTitle,
  VideoContent,
  VideoGrid,
  ThumbnailImage,
} from "../../components/youtube-videos/youtube-videos.styles";
import { YoutubeModal } from "../../components/youtube-modal";
import { TikTokEmbed } from "./tik-tok-embed";

export const Home = () => {
  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);
  const navigate = useNavigate();

  const latestVideos = videos.slice(0, 3);

  const handleOpenModal = (videoId: string) => {
    setCurrentVideoId(videoId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentVideoId(null);
  };

  const desktopCopy =
    "Empowering you to conquer tech, business and life with power and precision";
  const mobileCopy = "Conquering the tech industry with passion and precision.";

  const navigateAndTrack = (navigateTo: string, event: string) => {
    navigate(navigateTo);
    trackEvent(event);
  };

 const handleViewTikTok = () => {
   window.location.href =
     "https://www.tiktok.com/@reneelouise.tech?is_from_webapp=1&sender_device=pc";
 };


  useEffect(() => {
    // Dynamically load the YouTube API script
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.onload = () => {
      // Once the script is loaded, we ensure the YouTube API is initialized.
      console.log("YouTube API loaded", window.gapi);

      // Initialize the Subscribe button after the script is loaded
      window.gapi.load("subscribe", () => {
        // Render the subscribe button
        window.gapi.ytsubscribe.render("subscribe-button", {
          channelid: "UCN9Lg6lta2x25ywspRqQEHg", // Your YouTube channel ID
          layout: "default", // You can change the layout (default, full)
          count: "default", // Show subscriber count or not
        });
      });
    };

    document.body.appendChild(script);

    // Cleanup the script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Background />
      <Container>
        <Slide triggerOnce>
          <SubHeading>
            <TypingEffect phrases={isMobile ? mobilePhrases : phrases} />
          </SubHeading>
        </Slide>
        <Fade delay={1e3} cascade damping={1e-1} triggerOnce>
          <MainHeading>{isMobile ? mobileCopy : desktopCopy}</MainHeading>
        </Fade>
      </Container>
      <Separator src={wavesSeparator} alt="" />
      <SectionContainer color="#fff">
        <AnotherSection>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <ValueText>Latest YouTube Videos</ValueText>

            <div
              className="g-ytsubscribe"
              data-channelid="UCN9Lg6lta2x25ywspRqQEHg"
              data-layout="default"
              data-count="default"
            ></div>
          </div>
          {/* Video Grid */}
          <VideoGrid>
            {latestVideos.map((video) => (
              <VideoCard
                key={video.id}
                onClick={() => handleOpenModal(video.id)}
              >
                <ThumbnailWrapper>
                  <ThumbnailImage src={video.thumbnailUrl} alt={video.title} />
                  <DurationTag>{video.duration}</DurationTag>
                </ThumbnailWrapper>
                <VideoContent>
                  <VideoCardTitle>{video.title}</VideoCardTitle>
                  <VideoCardDescription>
                    {video.description}
                  </VideoCardDescription>
                  <VideoCardMeta>
                    <VideoCardMetaItem>{video.publishedAt}</VideoCardMetaItem>
                    <VideoCardMetaItem>{video.duration}</VideoCardMetaItem>
                  </VideoCardMeta>
                </VideoContent>
              </VideoCard>
            ))}
          </VideoGrid>

          {/* Modal */}
          {isModalOpen && currentVideoId && (
            <YoutubeModal videoId={currentVideoId} onClose={handleCloseModal} />
          )}
          <ButtonGroup>
            <Button
              onClick={() =>
                navigateAndTrack("/youtube", "View all youtube videos click")
              }
            >
              View all videos
            </Button>
            <Button
              color="rgb(21, 13, 107)"
              onClick={() =>
                navigateAndTrack("/contact", "Collaborate button clicked")
              }
            >
              Collaborate
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <AnotherSection>
          <ValueText>Blaq Byte Podcast</ValueText>
          <IFrame
            src="https://www.buzzsprout.com/2115341/12425380-career-switcher-series-episode-1-switching-from-software-engineering-to-product-management-at-goldman-sachs-ft-seun-afolayan?client_source=small_player&iframe=true"
            loading="lazy"
            width="100%"
            height="200"
            frameBorder="0"
            scrolling="no"
            title="Switching from Software Engineering to Product Management at Goldman Sachs ft Seun Afolayan "
          ></IFrame>
          <ButtonGroup>
            <Button
              onClick={() =>
                navigateAndTrack("/podcast", "View all podcast episodes click")
              }
            >
              View all episodes
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <Separator src={lineSeparator} alt="" />
        <AnotherSection color="#fff">
          <Text>Speaking Engagements</Text>
          <CustomerStoriesContainer>
            {extraStories.map((story, key) => {
              return (
                <>
                  <Zoom key={key} delay={key * 100} triggerOnce>
                    <Story key={key}>
                      <StoryImage
                        src={story.src}
                        alt={story.type}
                        title={story.title}
                      />
                      <Type>{story.type}</Type>
                      <Description aria-label={story.title}>
                        {story.shortDescription}
                      </Description>
                    </Story>
                  </Zoom>
                </>
              );
            })}
          </CustomerStoriesContainer>
          <ButtonGroup>
            <Button
              onClick={() =>
                navigateAndTrack(
                  "/speaking",
                  "View all speaking engagements click"
                )
              }
              aria-label="Click to go to the customer stories page"
            >
              View All
            </Button>
            <Button
              color="#031f48"
              onClick={() => navigateAndTrack("/contact", "Book now click")}
              aria-label="Click to book Renee now for speaking opportunities"
            >
              Book Now
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <Separator src={slantedLineSeparator} alt="" />
        <SectionContainer color="#F7F8FC">
          <Section color="#F7F8FC" templateColumns="1fr">
            <Slide direction="right" triggerOnce>
              <TextContainer>
                <Text>Testimonials</Text>
                <Slider content={testimonials} />
              </TextContainer>
            </Slide>
          </Section>
        </SectionContainer>
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <AnotherSection color="#fff">
          <Text>Check out my TechTok 🚀 </Text>
          <CustomerStoriesContainer>
            {tiktoks.map((video, index) => (
              <TikTokEmbed
                key={index}
                videoId={video.videoId}
                username={video.username}
                description={video.description}
                tags={video.tags}
              />
            ))}
          </CustomerStoriesContainer>
          <ButtonGroup>
            <Button color="#031f48" onClick={handleViewTikTok}>
              Go to TikTok
            </Button>
          </ButtonGroup>
        </AnotherSection>
        <Separator src={singleLineWave} alt="" />
        <ContactMini heading=" " content="Let's connect" />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#fff"
          logo={logoLight}
        />
      </SectionContainer>
    </>
  );
};
