import styled, { css } from "styled-components";

interface StyleProps {
  color?: string;
  templateColumns?: string;
}

interface IsHoveredProps {
  isHovered: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #150d6b;
  height: 70vh;
  justify-content: center;
  padding: 36px;
`;

export const MainHeading = styled.h1`
  font-size: 20px;
  max-width: 56rem;
  color: #fff;
`;

export const MainSubHeading = styled.h3`
  font-size: 20px;
  max-width: 56rem;
  z-index: 1;
  margin: 0;
`;

export const ServiceSubheadingText = styled(MainSubHeading)`
  color: #150d6b;
`;

export const HeadingText = styled.h2`
  font-size: 20px;
  margin: 12px 0;
  max-width: 56rem;
  font-weight: 400;
  color: #fff;
`;

export const CustomerStoryHeadingText = styled(HeadingText)`
  color: unset;
`;

export const SeparatorContainer = styled.div``;

export const BannerText = styled(HeadingText)`
  font-size: 1.2rem;
`;

export const SubHeading = styled.h1`
  font-size: 4em;
  color: #fff;
  z-index: 1;
  margin: 0;

  @media screen and (max-width: 990px) {
    font-size: 2.8em;
    width: 95%;
  }
`;

export const SectionContainer = styled.section<StyleProps>(
  ({ color }: StyleProps) => css`
    background-color: ${color};
    padding-bottom: 48px;
    align-items: center;
    display: flex;
    flex-direction: column;
  `
);

export const Section = styled.section<StyleProps>(
  ({ color, templateColumns }: StyleProps) => css`
    background-color: ${color};
    display: grid;
    grid-template-columns: ${templateColumns};
    align-items: center;
    padding: 36px;

    @media screen and (max-width: 1250px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
  `
);

export const ResourceSection = styled(Section)`
  padding: unset;
`;
export const BottomSection = styled(Section)`
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

export const AnotherSection = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSeparator = styled.img`
  width: 100%;
  rotate: 180deg;
`;

export const Separator = styled(StyledSeparator)`
  position: relative;
  top: 4px;
`;

export const BottomSeparator = styled(StyledSeparator)`
  top: 0px;
  rotate: 0deg;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
`;

export const SectionTwoTextContainer = styled(TextContainer)`
  text-align: center;
`;

export const Text = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  color: #150d6b;
  margin: 0px;

  @media screen and (max-width: 1250px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 990px) {
    max-width: 100%;
    font-size: 1.875rem;
  }
`;

export const TextContent = styled.h2`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 1.5em;
`;

export const ExtraText = styled.p`
  margin: 12px 0;
  max-width: 56rem;
  font-weight: 400;
  color: inherit;
  font-size: 1.125rem;
  // width: 70%;

  @media screen and (max-width: 990px) {
  }
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #150d6b;
`;

export const Circle = styled.div`
  border-radius: 613px 817px 539px 675px;
  height: 500px;
  width: 500px;
  border: 2px solid #150d6b;
  background-color: #150d6b;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1250px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 24rem;
  cursor: pointer;
  border-radius: 817px 539px 675px;
  top: -30px;
  left: 90px;
  position: absolute;
`;

export const Button = styled.button<IsHoveredProps>(
  ({ isHovered }: IsHoveredProps) => css`
    cursor: pointer;
    width: fit-content;
    font-size: 1.125rem;
    color: ${isHovered ? "#fff" : "#150d6b"};
    background-color: ${isHovered ? "#150d6b" : "#fff"};
    transition: all 0.2s ease-in-out 0s;
    border-radius: 30%;
    border: 2px solid #150d6b;
    padding: 14px 40px 14px 28px;
    border-radius: 100px;
    font-weight: 500;

    @media screen and (max-width: 990px) {
      align-self: center;
    }
  `
);

export const SeeMoreButton = styled.button`
  cursor: pointer;
  width: fit-content;
  font-size: 1.125rem;
  color: grey;
  background-color: #fff;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 30%;
  border: 2px solid grey;
  padding: 14px 40px 14px 28px;
  border-radius: 100px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #150d6b;
  }
`;

export const ServiceCardContainer = styled.div<StyleProps>(
  ({ color }: StyleProps) => css`
    display: flex;
    flex-direction: column;
    background-color: ${color ? color : "#fff"};
    padding: ${color ? "24px" : "36px"};
    border-radius: 4px;
    margin: 18px;
    height: -webkit-fill-available;

    @media screen and (max-width: 700px) {
      height: auto;
    }
  `
);

export const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CustomerStoryContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 36px;

  @media screen and (max-width: 990px) {
    padding: unset;
  }
`;

export const ServiceText = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  color: #150d6b;
  line-height: 160%;
  margin-bottom: 1.5em;
`;

export const ResourceOffering = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f7f8fc;
  border-radius: 8px;
  padding: 24px;
  margin: 12px;
  height: -webkit-fill-available;

  @media screen and (max-width: 700px) {
    height: auto;
  }
`;

export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ResourceIcon = styled.img`
  width: 50px;
  height: 50px;
`;

export const ResourceText = styled(ExtraText)`
  width: 100%;
`;

export const ServiceOffering = styled(ResourceOffering)`
  background-color: #fff;
`;
