import { useEffect, useState } from "react";
import {
  Nav,
  LogoContainer,
  Logo,
  NavLinks,
  MenuItem as NavLink,
  LogoNavLink,
} from "./navbar.styles";
import { logoDark, logoLight } from "../../assets";
import { Dispatch } from "react";
import { SetStateAction } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { trackEvent } from "../../helpers";

type ValidRouteProps = {
  isValidRoute: boolean;
  isResumePage: boolean;
  isMobile: boolean;
  setIsMenuIconClicked: Dispatch<SetStateAction<boolean>>;
  isMenuIconClicked: boolean;
};

export const NavBar = ({
  isValidRoute,
  isMobile,
  isResumePage,
  isMenuIconClicked,
  setIsMenuIconClicked,
}: ValidRouteProps) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const displayDarkLogoNav = !isValidRoute || isMenuIconClicked;

  const displayMobileNavIcon = () => {
    return (
      isMobile && (
        <Hamburger
          toggled={isMenuIconClicked}
          toggle={setIsMenuIconClicked}
          color={displayDarkLogoNav ? "#150d6b" : "#fff"}
        />
      )
    );
  };

  // events tracking
  const handleLogoClick = () => {
    setIsMenuIconClicked(false);
    trackEvent("Logo click");
  };

  // check if page has been scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Nav
      isValidRoute={isValidRoute}
      isResumePage={isResumePage}
    >
      <LogoContainer>
        <LogoNavLink to="/" isScrolled={isScrolled} isValidRoute={isValidRoute}>
          <Logo
            onClick={() => handleLogoClick()}
            src={
              displayDarkLogoNav || (isMobile && isMenuIconClicked)
                ? logoDark
                : logoLight
            }
            alt="Renee Louise"
            title="Click to go to the home page"
          />
        </LogoNavLink>
      </LogoContainer>

      {isMobile ? (
        displayMobileNavIcon()
      ) : (
        <NavLinks isValidRoute={isValidRoute}>
          <NavLink
            to="/"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("Home page click")}
          >
            Home
          </NavLink>  <NavLink
            to="/about"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("About page click")}
          >
            About
          </NavLink>

          <NavLink
            to="/resume"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("Resume page click")}
          >
            Resume
          </NavLink>
          <NavLink
            to="/podcast"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("Podcast page click")}
          >
            Podcast
          </NavLink>
          
          <NavLink
            to="/youtube"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("YouTube page click")}
          >
            YouTube
          </NavLink>
          
            <NavLink
            to="/services"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("Services page click")}
          >
            Services
          </NavLink>
          <NavLink
            to="/contact"
            isScrolled={isScrolled}
            isValidRoute={isValidRoute}
            onClick={() => trackEvent("Contact page click")}
          >
            Contact
          </NavLink>

        </NavLinks>
      )}
    </Nav>
  );
};
