import React, {useEffect, useState} from "react"
import {
  slantedLineSeparator,
  slantedLineSeparatorDark,
  logoLight,
  logoDark,
  selfie,
  docFileDark,
  pdfDark,
} from "../../assets";
import {
  testimonials,
  technicalProjects,
  experience,
  skills,
  links,
  cvCertificates,
  handleDownload,
  mobilePhrases,
  CVphrases,
} from "../../helpers";
import {
  Links,
  CvSection,
  CvSubHeading,
  BannerText as Slide,
  Section,
  SectionContainer,
  NameAndDocContainer,
  Separator,
  TextContainer,
  DocContainer,
  Text,
  Image,
  Name,
  Link,
  BottomSeparator,
  Document,
  Cv,
  HeadingOuterContainer,
  HeadingTextContainer,
  HeadingImageContainer,
} from "./resume.styles";
import { Footer } from "../../components/footer";
import { Background } from "../../components/background";
import { ContactMini } from "../../components/contact-mini";
import { Slider as TestimonialSlider } from "../../components/slider/slider";
import { ProjectBulletPoint } from "../../components/tech-project-bullet";
import { CvLinks } from "../../components/cv-links";
import { useViewportDimensions } from "../../hooks/viewPortDimensions";
import { TypingEffect } from "../../components/typing-effect/typing-effect";
import { Button, Container, ErrorMessage, Input, InputContainer, MainHeading, SubHeading } from "../../index.styles";

export const Resume = () => {
  const [password, setPassword] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [displayErrorToast, setDisplayErrorToast] = useState<boolean>(false);
  const [attemptCount, setAttemptCount] = useState<number>(0); // Track password attempts
  const [emptyInputWarning, setEmptyInputWarning] = useState<boolean>(false); // Track empty input warning

  const { width } = useViewportDimensions();
  const isMobile = width <= 728;

  const correctPassword = "Typescript123";

  // Error messages in a sensible sequence
  const errorMessages = [
    "Oops! That’s not it. Please try again.", // 1st attempt
    "Oops! That’s not it. Please try again.", // 1st attempt
    "Hmm, still not the right password. Have you contacted Renée for access to this?", // 2nd attempt
    "Nice effort! But that’s not quite right. Don’t give up!", // 3rd attempt
    "So close, yet so far! Maybe a little break will help?", // 4th attempt
    "Hmm, still incorrect. At this point, you might want to take a breather.", // 5th attempt
    "I admire your tenacity! If you keep this up, I might just give you the password 😉", // 6th attempt and beyond
  ];

  // Check for stored authentication in sessionStorage
  useEffect(() => {
    const storedAuth = sessionStorage.getItem("isAuthenticated");
    if (storedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Check for empty input
    if (!password.trim()) {
      setEmptyInputWarning(true); // Show warning for empty input
      return; // Prevent further processing
    }

    // Handle password authentication
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setPassword(""); // Clear input field after authentication
      setAttemptCount(0); // Reset attempt count after successful login
      setEmptyInputWarning(false); // Clear empty input warning
      sessionStorage.setItem("isAuthenticated", "true"); // Store authentication status
    } else {
      // Increment attempt count only if the password is incorrect
      setAttemptCount((prev) => prev + 1); // Increment attempt count
      setDisplayErrorToast(true); // Display error message
      setEmptyInputWarning(false); // Clear empty input warning
    }
  };


  // Hide error message when user starts typing again
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setDisplayErrorToast(false); // Hide error message
    setEmptyInputWarning(false); // Hide empty input warning
  };

  const desktopCopy =
    "Please enter the password you were given for full access to my Resume.";
  const mobileCopy = "";

  if (!isAuthenticated) {
    return (
      <>
        <Background />
        <Container>
          <Slide triggerOnce>
            <SubHeading>
              <TypingEffect phrases={isMobile ? mobilePhrases : CVphrases} />
            </SubHeading>
          </Slide>
          <MainHeading>{isMobile ? mobileCopy : desktopCopy}</MainHeading>
          <InputContainer onSubmit={handlePasswordSubmit}>
            <Input
              type="password"
              value={password}
              onChange={handleInputChange} // Call this when typing
              placeholder="Enter password"
            />
            <Button type="submit">Submit</Button>
            {emptyInputWarning && (
              <ErrorMessage>
                Uh-oh! Please type something in before you submit.
              </ErrorMessage>
            )}
            {displayErrorToast && (
              <ErrorMessage>
                {
                  attemptCount < 7
                    ? errorMessages[attemptCount]
                    : errorMessages[6] // Show last message after 5 attempts
                }
              </ErrorMessage>
            )}
          </InputContainer>
        </Container>
      </>
    );
  }

  return (
    <>
      <Background />
      <Separator src={slantedLineSeparator} alt="" />
      <CvSection>
        <Cv>
          <HeadingOuterContainer>
            <HeadingTextContainer>
              <NameAndDocContainer>
                <Name src={logoDark} alt="renee louise" />
                <DocContainer>
                  <Document
                    src={docFileDark}
                    alt="click to download cv in word format"
                    onClick={() => handleDownload("./cv.docx")}
                  />
                  <Document
                    src={pdfDark}
                    alt="click to download cv in pdf format"
                    onClick={() => handleDownload("./cv.pdf")}
                  />
                </DocContainer>
              </NameAndDocContainer>
              <Links>
                {links.map((link) => (
                  <Link>
                    <CvLinks
                      icon={link.icon}
                      alt={link.alt}
                      text={link.text}
                      url={link.url}
                    />
                  </Link>
                ))}
              </Links>

              <p>
                Economics graduate and Senior Software Engineer with over 6
                years of experience in full-stack development, specialising in
                React and TypeScript. I have a proven track record of
                architecting and delivering scalable, high-performance web
                applications in agile environments. I excel at translating
                complex technical requirements for stakeholders, driving
                technical initiatives, and leading high-performing teams. Known
                for meticulous documentation and problem-solving, I actively
                mentor developers and contribute to the tech community by
                hosting the Blaq Byte podcast, where I support underrepresented
                groups in tech by providing industry insights, career advice,
                and mentoring opportunities. Additionally, I speak at industry
                events and meetups with audiences of up to 150 attendees.
                Seeking to bring my expertise to a dynamic environment where I
                can continue to innovate and lead.
              </p>
            </HeadingTextContainer>
            <HeadingImageContainer>
              <Image src={selfie} alt="picture of Renée smiling" />
            </HeadingImageContainer>
          </HeadingOuterContainer>

          <CvSubHeading>Technical Projects</CvSubHeading>
          <ul>
            {technicalProjects.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>
          <CvSubHeading>Experience</CvSubHeading>
          <ul>
            {experience.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>

          <CvSubHeading>Education &amp; Certificates</CvSubHeading>
          <ul>
            {cvCertificates.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>
          <CvSubHeading>Skills</CvSubHeading>
          <ul>
            {skills.map((project) => (
              <li>
                <ProjectBulletPoint
                  startText={project.startText}
                  endText={project.endText}
                  skills={project.skills}
                />
              </li>
            ))}
          </ul>

          <CvSubHeading>Community Leadership &amp; initiatives</CvSubHeading>
          <ul>
            <li>
              <ProjectBulletPoint
                startText="Founder &amp; Podcast Host, Blaq Byte: "
                endText="Host and produce a podcast supporting underrepresented groups in tech, featuring industry experts and professionals to share key insights, career advice, and mentoring opportunities for career switchers and tech enthusiasts."
              />
            </li>
          </ul>
        </Cv>
      </CvSection>
      <SectionContainer color="#fff">
        <BottomSeparator src={slantedLineSeparator} alt="" />
        <Section color="#fff" templateColumns="1fr">
          <Slide direction="right" triggerOnce>
            <TextContainer>
              <Text>Testimonials</Text>
              <TestimonialSlider content={testimonials} />
            </TextContainer>
          </Slide>
        </Section>
        <Separator src={slantedLineSeparator} alt="" />
        <ContactMini color="#F7F8FC" />
        <Footer
          separator={slantedLineSeparatorDark}
          color="#F7F8FC"
          logo={logoLight}
        />
      </SectionContainer>
    </>
  );
};
